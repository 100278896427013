import { Card, CardDescription, CardHeader, CardTitle } from "@/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { useEffect, useRef, useState } from "react";
import { LogsTable } from "./LogsTable";
import { Separator } from "@/ui/separator";
import { DateRangePicker } from "./DateRangePicker";
import { Button } from "@/ui/button";
import { SpreadsheetButton } from "./SpreadsheetButton";
import { useInfiniteQuery } from "@tanstack/react-query";
import { RdsResponse, sevenDaysAgo } from "@/types/types";
import { convertToStartOfDayUTC, convertToEndOfDayUTC } from "@/types/types";
import { getToken } from "@/auth";

const fetchLogs = async (startDate: Date, endDate: Date, page: string, lastKey?: string): Promise<RdsResponse> => {
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const baseURL = BASE_URL + "/api"

  try {
    const queryParams = new URLSearchParams({
      limit: '15', // Adjust as needed
      from: parseInt((convertToStartOfDayUTC(startDate).getTime() / 1000).toFixed(0)).toString(),
      to: parseInt((convertToEndOfDayUTC(endDate).getTime() / 1000).toFixed(0)).toString(),
      page,
    });

    const headers: Record<string, string> = {};
    if (lastKey) {
      headers['X-Next-page-key'] = lastKey;
    }

    var token = getToken()
    if (!token) {
      token = ""
    }

    headers['Authorization'] = "Bearer " + token;

    const response = await fetch(baseURL + `/logs/rds-queries?${queryParams.toString()}`, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log(response)
    const data = await response.json();
    console.log("Fetched Data:", data);
    return data

  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const ModuleTabs = () => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(true);
  const [isScrolledRight, setIsScrolledRight] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("rds_queries");

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const checkHorizontalScrollPosition = () => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setIsScrolledLeft(scrollLeft === 0);
      setIsScrolledRight(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const scrollTabs = (direction: 'left' | 'right') => {
    if (tabsRef.current) {
      const scrollAmount = 448; // 2 tabs = 440px + 8px padding so 2 new tabs become visible
      if (direction === 'left') {
        tabsRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else {
        tabsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  // useEffect that checks the horizontal scroll position on h-scroll or resize
  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.addEventListener('scroll', checkHorizontalScrollPosition);
      window.addEventListener('resize', checkHorizontalScrollPosition);
      checkHorizontalScrollPosition();
      return () => {
        tabsRef.current?.removeEventListener('scroll', checkHorizontalScrollPosition);
      };
    }
  }, []);

  const initialPageParam = { page: 1, lastKey: null as string | null };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,  // Function to fetch the next page
    hasNextPage,    // Determines if there's more data to fetch
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["logs"],
    queryFn: ({ pageParam = initialPageParam }) => {
      if (startDate && endDate) {
        return fetchLogs(startDate, endDate, "" + pageParam.page, "" + pageParam.lastKey);
      }
      return Promise.resolve({
        items: [], pagination: {
          current_page: 1,
          last_key: '',
          next_page: null,
          prev_page: null,
          total_pages: 1,
          total_records: 0
        }
      }); // Default empty response if dates are not set
    },
    enabled: false, //selectedTab === "rds_queries" && startDate !== undefined && endDate !== undefined, // Only fetch data when the first tab is active
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.last_key) {
        return {
          page: lastPage.pagination.current_page + 1,
          lastKey: lastPage.pagination.last_key,
        };
      } else {
        return undefined; // No more pages to load
      }
    },
    initialPageParam: initialPageParam,
  });

  useEffect(() => {
    refetch(); // Trigger fetch on component mount
  }, [refetch]);

  const handleApplyClick = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    refetch();
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (!isLoading && hasNextPage && scrollHeight - scrollTop <= clientHeight + 1) {
      fetchNextPage(); // Fetch the next page of data
    }
  };

  const tabClassName = `flex-1 min-w-[220px] padding-2 rounded-none border-b-2 border-transparent duration-150 hover:border-coal inset-x-32
                        data-[state=active]:scale-102 data-[state=active]:border-black data-[state=active]:text-black data-[state=active]:bg-[#FBFBFB] data-[state=active]:drop-shadow-xl`

  const logs = data?.pages.flatMap(page => page.items) || [];

  return (
    <div className="mt-2 relative">
      <Tabs value={selectedTab} onValueChange={setSelectedTab} className="w-full" defaultValue="rds_queries">
        <button
          onClick={() => scrollTabs('left')}
          className={`z-40 h-9 absolute left-0 mx-2  padding-2 bg-[#FBFBFB] border-b-2 border-transparent hover:border-coal
          ${isScrolledLeft ? 'cursor-not-allowed opacity-50 hover:border-transparent' : 'cursor-pointer duration-150'}
          ${isScrolledLeft && isScrolledRight ? 'hidden' : ''}`}
        >
          &lt;
        </button>
        <TabsList ref={tabsRef} className="clip-tabs flex overflow-x-auto no-scrollbar justify-around bg-[#FBFBFB]">
          <TabsTrigger value="rds_queries" className={tabClassName}>RDS Queries</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_rds" className={tabClassName}>AWS RDS</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_secrets" className={tabClassName}>AWS Secrets</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_sqs" className={tabClassName}>AWS SQS</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="private_bin" className={tabClassName}>Privatebin</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="secret_migration" className={tabClassName}>Secret Migration</TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="streaming_platform" className={tabClassName}>Streaming Platform</TabsTrigger>
        </TabsList>
        <button
          type="button"
          onClick={() => scrollTabs('right')}
          className={`z-40 h-9 absolute right-0 mx-2 padding-2 -translate-y-[40px] transform bg-[#FBFBFB] border-b-2 border-transparent duration-150 hover:border-coal
            ${isScrolledRight ? 'cursor-not-allowed opacity-50 hover:border-transparent' : 'cursor-pointer duration-150'}
            ${isScrolledLeft && isScrolledRight ? 'hidden' : ''}`}
        >
          &gt;
        </button>

        <div className="">
          <TabsContent value="rds_queries">
            <Card className="h-full mt-2">
              <CardHeader>
                <CardTitle className="flex items-center gap-8 mb-2 ml-4">
                  RDS Queries logs
                  <SpreadsheetButton startDate={convertToStartOfDayUTC(startDate)} endDate={convertToStartOfDayUTC(endDate)} />
                </CardTitle>
                <CardDescription className="mb-2 ml-4">
                  <div className="flex items-center gap-4">
                    <span>Filter by:</span>
                    {/* Account
                        <MultiSelectDropdown itemName="account" data={accountsArray}/>
                        Requester
                        <MultiSelectDropdown itemName="requester" data={requestersArray}/>
                        Approver
                        <MultiSelectDropdown itemName="approver" data={approversArray}/> */}
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    // onDateRangeChange={handleDateRangeChange}
                    />
                    <Button type="submit" onClick={handleApplyClick} className="drop-shadow-olx m-1 transition-all rounded-md border duration-150 hover:ring hover:ring-inset hover:ring-gray-700">Apply</Button>
                    <Button type="reset" className="bg-red-300 hover:bg-red-300 drop-shadow-olx m-1 transition-all rounded-md border duration-150 hover:ring hover:ring-inset hover:ring-gray-700">Clear</Button>
                  </div>
                </CardDescription>
                {/* <div className="overflow-y-scroll max-h-[600px]" onScroll={handleScroll}> */}
                {
                  error ? (
                    <div className="flex justify-center">
                      <p className="ml-4 mt-8">Error loading data: {error.message}</p>
                    </div>
                  ) : isLoading ? (
                    <div className="flex justify-center">
                      <p className="ml-4 mt-8">Loading...</p>
                    </div>
                  ) : logs.length ? (
                    <div ref={scrollableDivRef} className="h-[calc(100vh-300px)] overflow-auto mb-4" onScroll={handleScroll}>
                      <LogsTable
                        data={logs ?? []}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <p className="ml-4 mt-8">No data available.</p>
                    </div>
                  )
                }

                {/* </div> */}
              </CardHeader>
            </Card>
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
}